//Component Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';

//Image Imports
import logo from './images/TS.svg';
import me from './images/me.png';
import timer from './images/timer.svg';
import magnifier from './images/magnifier.svg';
import puzzle from './images/puzzle.svg';
import insider_threat_cert from './images/insider_threat_cert.png';
import decision_making_cert from './images/decision_making_cert.png';
import resume from './assets/Tristan_Scott_Resume.pdf';

//CSS Imports
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Header-content">
          <img src={logo} className="App-logo" alt="logo" />
          <ul className="Link-list">
            <li>
              <Nav.Item>
                <Nav.Link href={resume} target='none'><span className='App-link'>Resume</span></Nav.Link>
              </Nav.Item>
            </li>
            <li>
              <Nav.Item>
                <Nav.Link href="mailto:me@tristan-scott.com"><span className='App-link'>Contact</span></Nav.Link>
              </Nav.Item>
            </li>
            <li>
              <Dropdown as={NavItem}>
                <Dropdown.Toggle as={NavLink}><span className='App-link'>Links</span></Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href='https://www.linkedin.com/in/trs15/' target='none'>LinkedIn</Dropdown.Item>
                  <Dropdown.Item href="https://github.com/trs15" target='none'>Github</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </header>
      <main className="App-main">
        <div class="Section-top">
          <Container>
            <Row className='Row-pad-top'>
              <Col><h1>DEVOPS, SRE AND MUCH MORE</h1></Col>
            </Row>
            <Row className='Row-pad-bottom'>
              <Col><p>I like solving <b>BIG</b> problems, one <i>small</i> problem at a time...</p></Col>
            </Row>
            <Row className="justify-content-lg-center">
              <Col sm lg={5} className='Pad-bottom'>
                <Image className='Profile-image' src={me} thumbnail/>
              </Col>
              <Col sm lg={5} className='Centered-textbox'>
                <p>Hello! I'm Tristan - a motivated, hard working DevOps Engineer with a passion for simple solutions. I currently have four years of experience working in hosted operations at the enterprise level. My day-to-day consists of supporting software services that are used by several large retailers with <b>thousands</b> of stores and <b>millions</b> of customers.</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="Section">
            <Container>
              <Row>
                <Col>
                  <img src={timer} alt='Clock Timer' className='Skill-icon'/>
                  <div className='Hexagon-text'>Fast Learner</div>
                </Col>
                <Col>
                  <img src={puzzle} alt='Puzzle Piece' className='Skill-icon'/>
                  <div className='Hexagon-text'>Problem Solver</div>
                </Col>
                <Col>
                  <img src={magnifier} alt='Magnifying Glass' className='Skill-icon'/>
                  <div className='Hexagon-text'>Detail Oriented</div>
                </Col>
              </Row>
            </Container>
        </div>
        <div className="Section Dark">
          <div className="Content">
            <h1>Skills</h1>
            <Container className='Pad-top'>
              <Row>
                <Col lg={3} md={6}>
                  <h3 className='Skill-title'>DevOps</h3>
                  <ul className='Skill-list'>
                    <li>Azure DevOps</li>
                    <li>Google Cloud DevOps</li>
                    <li>Kubernetes Management</li>
                    <li>FluxCD</li>
                    <li>Ansible</li>
                    <li>Helm</li>
                    <li>Github Actions</li>
                    <li>JFrog</li>
                    <li>Terraform</li>
                  </ul>
                </Col>
                <Col lg={3} md={6}>
                  <h3 className='Skill-title'>SRE</h3>
                  <ul className='Skill-list'>
                    <li>Azure Monitor</li>
                    <li>Azure Diagnostics</li>
                    <li>Site 24x7</li>
                    <li>Custom Monitoring</li>
                    <li>Elastalerts</li>
                    <li>Kibana</li>
                    <li>PagerDuty Integrations</li>
                  </ul>
                </Col>
                <Col lg={3} md={6}>
                  <h3 className='Skill-title'>Development</h3>
                  <ul className='Skill-list'>
                    <li>MSSQL (SSIS)</li>
                    <li>Python</li>
                    <li>Powershell</li>
                    <li>Data Piplines</li>
                    <li>Git</li>
                    <li>RESTful APIs</li>
                    <li>Web Development</li>
                    <li>React</li>
                  </ul>
                </Col>
                <Col lg={3} md={6}>
                  <h3 className='Skill-title'>IT</h3>
                  <ul className='Skill-list'>
                    <li>Hardware Repair</li>
                    <li>Neworking</li>
                    <li>PC/Server Building</li>
                    <li>Device Management</li>
                    <li>Windows Support</li>
                    <li>Linux Support</li>
                    <li>Hosting Management</li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="Section Light">
          <Container>
            <Row className='justify-content-lg-center'>
              <h1 className='Pad-bottom'>Experience</h1>
              <Col lg={5} className='Pad-bottom'>
                <h3>DevOps Engineer</h3>
                <p>At my current position, I am responsible for the deployment and maintenance of several software services that are used by several large retailers with thousands of stores and millions of customers. I have been able to automate several manual processes, saving the company time and money.</p>
              </Col>
              <Col lg={5} className='Pad-bottom'>
                <h3>IT Support</h3>
                <p>Before my current position, I worked as an IT Support Specialist. I was responsible for the day-to-day support of several hundred users, as well as the maintenance of several servers and network devices. I also built custom websites, web apps and managed web hosting for dozens of customers.</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="Section Blue">
          <Container>
            <Row>
              <h1 className='Pad-bottom'>Education</h1>
              <Col>
                <p>I attended Auburn University where I graduated in 2020 with a Bachelor of Science in Software Engineering. During my time at Auburn I also completed a three semester Co-Op work program gaining valuable work experience along with my education.</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="Section White">
          <Container>
            <Row>
              <h1 className='Pad-bottom'>Certificates</h1>
              <Col>
                <a href="https://www.linkedin.com/learning/certificates/76ccdbc5dd6dde09cdfc6f1a7cff119e4e5a81aabf17426f8bad3227e36a2172?trk=share_certificate #insiderriskmanagement #cybersecurity." target='none'><img className='Certificate-image' src={insider_threat_cert} alt="Insider Threat Risk Management"/></a>
              </Col>
              <Col>
                <a href='https://www.linkedin.com/learning/certificates/1268841a674a2d17021e12a3e4f22918cce73bc0914294d0258acfe45e111e06' target='none'><img className='Certificate-image' src={decision_making_cert} alt="Decision Making Strategies"/></a>
              </Col>
            </Row>
          </Container>
        </div>
      </main>
      <footer className="App-footer">
        <p>&#169;tristan-scott.com {new Date().getFullYear()}</p>
      </footer>
    </div>
  );
}

export default App;
